export const APP_CONFIG = {
  APPLICATION_NAME: "Dashboard Vitaliteitsknip Heerlen",
  production: true,
  environment: "PROD",
  TEAMS_API: "https://www.google.com/",
  API: "https://api.iota.heerlen.vitaliteitsknip.nl/",
  HASURA_ADMIN_KEY: null,
  TIMER_TICK_RATE: 100,
  JWT: null,
  MSAL: {
    CLIENT_ID: "ea7bf8b0-8447-44a7-845f-e656596c5b18",
    AUTHORITY: "https://vitknip.b2clogin.com/vitknip.onmicrosoft.com/B2C_1_PROD",
    KNOWN_AUTHORITY: "https://vitknip.b2clogin.com/",
  },
};
